@import "_constants.scss";
@import "_colors.scss";
     
.HoverMenuItem {
    &:hover {
        background-color: $banner-highlight;
        color: white; // this helps get rid of a gap
        box-shadow: 0px 2px 2px 0px black;
    }
}



.HoverMenuCategory {
    display: table-cell;
    width: 15rem;
    font-weight: bolder;
    background-color: $content-background; // flex-grow: 1;
    padding-bottom: 1rem;
    vertical-align: top;
    .HoverMenuCategoryHeader {
        color: $gretmar-more-blue;
        padding-top: 1.0rem;
        padding-left: 1.0rem;
        padding-right: 1.0rem;
        &:after {
            content: "";
            display: block;
            margin-top: .5rem;
            margin-bottom: 1rem;
            margin-left: 1rem;
            width: 3.5rem;
            border-bottom: 3px solid;
        }
    }
    &:hover {
        background-color: #e6e6e6;
    }
}

.HoverMenuArticle {
    font-size: 0.75rem;
    line-height: 1.5rem;
    text-align: left;
    color: $content-text-color;
    font-weight: normal;
    &:hover {
        background-color: $banner-highlight;
        color: white;
    }
}

.HoverMenuCategories {
    display: inline-table;
    font-size: 0.9rem;
    background-color: $content-background;
    color: $content-text-color;
    position: relative;
    max-height: 100%;
    border-top: 2px solid #00579D;
    @include shadow;
    .HoverMenuCategory {
        a {
            // transform: skew(30deg);
            margin: 0;
            color: inherit;
            font-size: inherit;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0rem;
            padding-bottom: 0rem;
            text-indent: 0.5rem;
            cursor: pointer;
            text-decoration: none;
            position: relative;
            display: flex;
        }
    }
    @media screen and (max-width: $touch-style-max-width) {
        min-height: 100%;
    }
}

.HoverMenu {
    z-index: 2000;
    border-top: 2px solid #00579D;
    color: $gretmar-blue;
    background-color: $banner-background;
    position: absolute;
    display: none;
    width: 17rem;
    top: calc(4rem - 1px);
    left: 0rem;
    box-shadow: 0px 2px 2px 0px $banner-highlight;
    &.Fancy{
        position: fixed;
        color: black;
        right: 0;
        left: 0;
        top: 4rem;
        border: none;
        background-color: transparent;
        width: auto;
        box-shadow: none;
        cursor: default;
        &:hover {
            .HoverMenuCategories {
                &:not(:hover) {
                    display: none;
                }
            }
        }
    }
}