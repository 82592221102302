@import "_constants.scss";
@import "_colors.scss";

.ContactUs {
    color: $gretmar-blue;
    position: fixed;
    margin: 1rem;
    margin-top: 0rem;
    padding-bottom: 1rem;
    background-color: lightgrey;
    border-bottom: 1px solid #e2e2e2;
    span {
        &.Key {
            font-weight: bold;
            font-size: 0.9rem;
        }
    }
    .Divider {
        border-bottom: 2px solid $gretmar-blue;
        margin-left: 1.4rem;
        margin-right: 1.4rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    h2 {
        font-size: 1em;
        color: $content-background;
        margin: 0;
        padding: .5rem;
        background-color: $gretmar-blue;
    }
    h4 {
        margin: 0;
        margin-top: .5rem;
        padding: .5rem;
        margin-left: 0.5rem;
    }
    p {    
        padding: 1rem;
        margin: 0;
        padding-top: 0rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    a {
        text-decoration: none;
        font-style: normal;
        padding: 0.3rem;

        &:hover {
            text-decoration: none;
        }
    }


    .Header {
        display: block;
        text-decoration: none;
        font-style: inherit;
        padding: 1.5em;
        font-size: 1.0em;
        font-weight: 700;
        text-shadow: 2px 2px $gretmar-blue;
        cursor: pointer;
        background-color: $gretmar-blue;
        color: $content-background;
        filter: grayscale(0%);
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border: 1px solid $gretmar-blue;
        // @include shadow;
    }
}