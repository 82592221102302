@import "_constants.scss";
@import "_colors.scss";
#waves {
    position: fixed;
    bottom: -25%;
    height: 50%;
    width: 100%;
    z-index: -1;
    transition: bottom 600ms ease-out;
}

.Content {
    background-color: $transparent-content-background;
    padding-top: 8rem;
    min-height: calc(100% - 8rem - 7rem);
    width: 100%;
    overflow: hidden;
    &.Main {
        position: relative;
    }
    &.NoScroll {
        position: fixed;
        width: 100vw; //need vw units when using position: fixed
        overflow-y: fixed; //take into account the scrollbar in the body
    }
    .CategoryHeader {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        max-width: 47rem;
        min-width: 20rem;
        margin: auto;
        @include shadow;
        img {
            max-width: 100%;
            visibility: hidden;
            height: 100%;
            width: 100%;
        }
    }
    @media screen and (max-width: $article-and-content-menu-and-contact-us-max-width) {
        .ContentMenu {
            right: calc(50% + 27rem/2);
            +.ContactUs {
                display: none;
                +.ArticleList {
                    padding-right: 1rem;
                }
            }
        }
    }
    @media screen and (max-width: $article-and-content-menu-max-width) {
        .ContentMenu {
            left: 0;
            right: auto;
        }
    }
    @media screen and (max-width: $touch-style-max-width) {
        .ContentMenu {
            display: none;
            +.ContactUs {
                +.ArticleList {
                    padding-left: 1rem;
                }
            }
        }
    }
}

.Article {
    line-height: 1.4rem;
    margin: auto;
    margin-top: 0;
    .Content {
        background-color: $article-background;
        border: 1px solid $article-border;
        padding: 1rem;
        margin: 0;
        width: calc(100% - 2rem - 2px);
    }
    h2 {
        text-align: left;
        font-size: 1.1rem;
        color: $gretmar-more-blue;
        margin-left: 1rem;
    }
    h3 {
        font-size: 1rem;
    }
    h4 {
        font-size: .9rem;
    }
    p {
        text-align: justify;
    }
}

.ArticleList {
    padding: 1rem;
    padding-top: 0;
    min-height: 100vh;
    .FancyHeader {
        margin-bottom: -6rem;
    }
    .Article {
        margin-bottom: -7rem;
        padding-top: 8rem;
        max-width: 47rem;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .Divider {
        color: slategray;
        text-align: center;
        border: none;
        font-size: 2rem;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        .Content {
            background-color: transparent;
            width: auto;
            border: none;
        }
        &:before {
            content: " ";
            display: inline-block;
            height: 0;
            border-bottom: 2px solid;
            flex: 1;
            margin-right: 1rem;
        }
        &:after {
            content: " ";
            display: inline-block;
            height: 0;
            border-bottom: 2px solid;
            flex: 1;
            margin-left: 1rem;
        }
    }
}

.ContentMenu {
    right: calc(50% + 41rem/2);
    min-width: 20rem;
    max-width: 20rem;
    width: 20rem;
    +.ContactUs {
        min-width: 15rem;
        max-width: 15rem;
        width: 15rem;
        left: calc(50% + 55rem/2);
        +.ArticleList {
            padding-left: 22rem;
            padding-right: 15rem;
        }
    }
}