@import "_constants.scss";
@import "_colors.scss";
.Search {
    position: absolute;
    right: 11rem;
    color: $content-background;
    height: 100%;
    line-height: 2rem;
    width: 100%;
    display: flex;
    max-width: 2.0rem;
    transition: all .15s linear;
    &.active {
        max-width: 11rem;
        right: 5rem;
        +a.Telephone {
            max-width: 0;
        }
    }
    @media screen and (max-width: $top-bar-width-tiny) {
        right: 5rem;
    }
    @media screen and (max-width: $top-bar-width-super-tiny) {
        right: 2rem;
        &.active {
            right: 2rem;
        }
    }
    .SearchIcon {
        width: 2.4rem;
        height: 2.4rem;
        margin: auto;
        background-image: url(../images/search.svg);
        background-position: center center;
        background-size: 1.2rem 1.2rem;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: all .15s ease;
        &:hover {
            transform: scale(1.2);
            color: black;
        }
    }
    &:focus-within {
        >.SearchResult {
            &.active {
                display: block;
            }
        }
    }
    form {
        align-items: center;
        max-width: 0rem;
        display: flex;
        height: 100%;
        transition: all .15s linear;
        opacity: 0;
        &.active {
            opacity: 1;
            max-width: 10rem;
        }
    }
    input {
        max-width: 8rem;
        width: 100%;
        height: 1.5rem;
        font-size: 1.1rem;
        text-indent: 0.5rem;
    }
    .SearchResult {
        z-index: 2000;
        border-top: 2px solid #00579D;
        color: $gretmar-blue;
        background-color: $banner-background;
        position: absolute;
        display: none;
        width: 25rem;
        max-width: calc(100vw - 6rem);
        top: 4rem;
        right: 0rem;
        box-shadow: 0px 2px 2px 0px $banner-highlight; // height: 28rem;
        min-height: 4rem;
        max-height: calc(70vh - 4.5rem);
        overflow-y: auto;
        &.active {
            display: block;
        }
        &:hover {
            display: block;
        }
        a {
            width: 100%;
        }
        .SearchResultItem {
            &:nth-of-type(even) {
                background-color: $banner-background-zebra;
            }
            &:hover {
                background-color: $banner-highlight;
                color: white; // this helps get rid of a gap
                box-shadow: 0px 2px 2px 0px black;
            }
            .SearchResultArticle {
                margin-left: 2rem;
            }
            .SearchResultDetails {
                font-size: 0.70rem;
                padding-left: 0.5rem;
                margin: 0;
            }
            .SearchResultTags {
                font-size: 0.70rem;
                padding-left: 0.5rem;
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow-x: hidden;
            }
        }
    }
}