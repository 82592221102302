@import "_constants.scss";
@import "_colors.scss";

.Box {
    border: 1px solid black;
    page-break-inside: avoid;
    break-inside: avoid;
    background-color: $banner-highlight;
    line-height: 1.6rem;
    height: 15rem;
    width: 21rem;
    flex-basis: 21rem;
    flex-grow: 0;
    margin: 0.5rem;
    position: relative;
    color: white;
    display: grid;
    user-select: none;
    @media screen and (max-width: $article-and-content-menu-max-width) {
        flex-grow: 1;
    }
    @media screen and (max-width: $touch-style-max-width) {
        flex-grow: 0;
    }
    @media screen and (max-width: $touch-style-two-column-max-width) {
        flex-grow: 1;
    }
    h5 {
        z-index: 2;
        font-size: 1em;
        font-weight: bold;
        display: block;
        margin: auto;
        position: absolute;
        margin-top: 7rem;
        width: 100%;
        transition: margin-top .25s linear .15s;
        text-align: center;
    }
    section {
        z-index: 1;
        display: block;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-top: 4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        opacity: 0;
        transition: opacity .25s linear .15s;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        ul {
            columns: 1;
            margin-top: .25rem;
            margin-bottom: .5rem;
            padding-left: 1rem;
            list-style: none;
            li {
                padding-bottom: 0;
                &:before {
                    content: "-";
                }
            }
        }
        p {
            margin-top: 0;
        }
        a.LearnMore {
            position: absolute;
            padding: .75rem;
            width: calc(50% - 1.5rem);
            text-align: center;
            color: white;
            font-weight: bold;
            font-style: inherit;
            &:hover {
                text-decoration: none;
                background-color: rgba(0, 0, 0, .2);
            }
            &.Bottom {
                bottom: 0;
            }
            &.Right {
                right: 0;
            }
            &.Left {
                left: 0;
            }
            &.Top {
                top: 0;
            }
        }
    }
    &:hover,
    &.active {
        background-color: $gretmar-blue;
        h5 {
            margin-top: 1.5rem;
            transition: margin-top .25s linear;
        }
        section {
            opacity: 1;
        }
    }
    &.ImageBox {
        h5 {
            margin-top: 1.5rem;
        }
        section {
            opacity: 1;
            background-color: transparent;
            .Image {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0;
                padding-bottom: .5rem;
                overflow: hidden;
                img {
                    object-fit: cover;
                }
            }
            .Information {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding-bottom: .5rem;
                opacity: 0;
                overflow: hidden;
                ul {
                    column-count: 1;
                    margin: 0;
                }
            }
        }
        &:hover,
        &.active {
            .Image {
                opacity: 1;
                transition: opacity .15s linear;
            }
            .Information {
                opacity: 0.95;
                transition: opacity .25s linear;
                background-color: rgba($gretmar-blue, .7);
                ul {
                    column-count: 1;
                    margin: 0;
                }
            }
        }
        &.ImageLinkBox {
            h5 {
                top: 0;
                margin: 0;
                line-height: 3rem;
                color: $content-background;
                background-color: $gretmar-blue-transparent;
            }
            section {
                margin: 0;
                padding: 0;
                opacity: 1;
                background-color: transparent;
                .Image {
                    padding: 0;
                    img {
                        object-fit: cover;
                        background-size: cover;
                        transition: all 0.75s linear;
                    }
                }
            }
            &:hover,
            &.active {
                background-color: $gretmar-blue;
                h5 {
                    color: white;
                }
                section {
                    .Image {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
}

.Content.Home {
    .Box {
        background-color: $gretmar-blue;
        flex-grow: 0;
        @media screen and (max-width: $touch-style-two-column-max-width) {
            flex-grow: 1;
        }
    }
}

.BoxList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}