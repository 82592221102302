@import "_constants.scss";
@import "_colors.scss";

.FancyHeader {
    max-width: 47rem;
    height: auto;
    margin: auto;
    display: flex;
    overflow: hidden;
    position: relative;
    .FancyContent {    
        // max-width: 20rem;
        height: fit-content;
        max-height: 6.5rem;
        -webkit-transform: skew(-30deg);
        transform: skew(-30deg);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
        // background-color: rgba(21, 34, 90, 0.7);
        background-color: $gretmar-blue;
        border-left: 1px solid black;
        margin-left: -4rem;
        padding-right: 1.5rem;
        z-index: 1;
        position: relative;
        .FancyHeaderTitle {
            font-size: 2rem;
            margin: 0.95rem;
            color: white;
            transform: skew(30deg);
            -webkit-transform: skew(30deg);
            margin-left: 4rem;
        }
    }
    .FancyHeaderImage {
        position: relative;
        width: 100%;
        height: 17rem;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            background-size: cover;
            background-position: center;
        }
        +.FancyContent {
            position: absolute;
        }
    }
}