@import "_constants.scss";
@import "_colors.scss";

.Banner {
    -moz-user-select: none;
    user-select: none;
    margin-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    position: fixed;
    left: 0;
    width: calc(100vw - 4rem); //need vw units when using position: fixed
    color: $gretmar-blue;
    background-color: $banner-background;
    @include shadow;
    z-index: 10;
    .BannerCategories {
        -webkit-transform: skew(-30deg);
        transform: skew(-30deg);
        display: flex;
        justify-content: center;
    }
    &.Narrow {
        overflow: visible;
        display: none;
        padding-left: 0rem;
        padding-right: 0rem;
        width: 100vw;
        .BannerCategories {
            -webkit-transform: none;
            transform: none;
            justify-content: flex-start;
            &.PlaceHolder {
                height: 0;
            }
        }
    }
    @media screen and (max-width: $touch-style-max-width) {
        display: none;
        &.Narrow {
            display: block;
        }
    }
}

.BannerLink {
    display: inline-block;
    font-style: inherit;
    color: white;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    line-height: 1.1rem;
    margin-right: -.1rem;
    font-size: 0.9rem;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    min-width: 0;
    flex-shrink: 0;
    &:hover {
        text-decoration: none;
        background-color: $banner-highlight;
        color: white; // this helps get rid of a gap
        // box-shadow: -1px 0px 0px 0px $banner-highlight;
    }
    &.active {
        background-color: $gretmar-blue;
        color: white; // this helps get rid of a gap
        box-shadow: 1px 0px 0px 0px $banner-highlight;
    }
}

.BannerCategory {
    -webkit-transform: skew(30deg);
    transform: skew(30deg);
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    white-space: nowrap;
}