@import "_constants.scss";
@import "_colors.scss";
.BreadCrumbs {
    position: relative;
    display: inline-block;
    height: 3rem;
    white-space: nowrap;
    font-style: inherit;
    padding-left: 2.4rem;
    padding-right: 1.4rem;
    margin-left: -1.4rem;
    min-width: 0;
    font-size: 0.9rem;
    text-decoration: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    background-color: $banner-background;
    &::before,
    &::after {
        content: " ";
        display: block;
        border-top: 1.55rem solid transparent;
        border-bottom: 1.55rem solid transparent;
        border-left: 1.40rem solid;
        position: absolute;
    }
    &::before {
        right: 2px;
        z-index: 20;
        border-left-color: rgba(122, 122, 122, 0.5);
    }
    &::after {
        right: 2px;
        top: 0px;
        z-index: 21;
        border-left-color: $banner-background;
    }
    &:first-child {
        .BreadCrumbsHoverMenu {
            left: 1.4rem;
        }
    }
    &:nth-child(n+3) {
        .BreadCrumbsHoverMenu {
            left: auto;
            right: 1.4rem;
        }
    }
    &:nth-child(2n+1) {
        background-color: lightgrey;
        &::after {
            border-left-color: lightgrey;
        }
    }
    &:hover,
    &.Active,
    &:focus, {
        &:not(.NoHover) {
            overflow: visible;
            background-color: $banner-highlight;
            color: white;
            &::before,
            &::after {
                border-left-color: $banner-highlight;
            }
        }
    }
    &.NoArrow {
        &::before,
        &::after {
            display: none;
        }
    }
    h2 {
        margin: 0;
        padding: 0;
        font-size: inherit;
        font-weight: inherit;
        max-width: 100%;
        padding-right: 1.4rem;
        line-height: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        &:after {
            content: '';
            display: inline-block;
            width: .5em;
            height: .5em;
            padding: 0;
            margin-left: .25rem;
            margin-bottom: 0.25rem;
            border-top: 2px solid;
            border-right: 2px solid;
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
            position: absolute;
            right: 1.6rem;
            top: 1.1rem;
        }
    }
}

.BreadCrumbsHoverMenu {
    position: absolute;
    top: 3rem;
    left: 1px;
    min-width: 100%;
    max-width: calc(100vw - 3rem);
    border: 1px solid $gretmar-blue;
    @include shadow;
    display: flex;
    flex-direction: column;
    background-color: $content-background;
    color: $gretmar-blue;
    z-index: 30;
}

.BreadCrumbsHoverMenuItem {
    display: flex;
    height: 2.1rem;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    &.Divider {
        font-weight: bold;
        color: $gretmar-more-blue;
        text-indent: -.5rem;
    }
    &.NoMenu {
        display: none;
    }
    &:hover {
        background-color: $banner-highlight;
        color: $content-background;
    }
    a {
        width: 100%;
        padding: 0.5rem;
        font-style: inherit;
        padding-left: 1.5rem;
        padding-right: .5rem;
        line-height: 1.1rem;
        font-size: 0.9rem;
        color: inherit;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}