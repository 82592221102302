a.NotFound {
    color: red;
    text-decoration: line-through;
    &:hover {
        text-decoration: line-through;
    }
}

img[src*="placeholder.png"],
img[style*="placeholder.png"] {
    border: 2px solid red;
    object-fit: fill;
    background-size: 100% 100%;
}