@import "_constants.scss";
@import "_colors.scss";

.Slide {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 1920px;
    margin: auto;
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
        background-size: contain;
        background-position: center;
    }
    .SlideCaption {
        height: 2.5rem;
        line-height: 2.5rem;
    }
    &.Caption {
        height: calc(100% - 2.5rem);
    }
    .SlideOverlay {
        -moz-user-select: none;
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        h2 {
            position: absolute;
            top: 10%;
            left: 5%;
            margin: 0;
            color: white;
            text-shadow: 2px 2px $gretmar-blue;
            font-size: 3.5vw;
        }
        @media screen and (max-width: $touch-style-max-width) {
            h2 {
                font-size: 5vw;
            }
        }
    }
}

.Slides {
    width: 100%;
    height: 30rem;
    max-height: 30rem;
    position: relative;
    cursor: pointer;
}

.SlideSelect {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2rem;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 0.5rem;
    .direction-selector {
        color: rgba(255, 255, 255, 0.5);
        position: relative;
        margin-left: .5rem;
        margin-right: .5rem;
        -moz-user-select: none;
        user-select: none;
        cursor: pointer;
        &:after {
            content: '';
            display: block;
            width: 2vw;
            height: 2vw;
            max-height: .75rem;
            max-width: .75rem;
            border-top: 2px solid;
            border-right: 2px solid;
            box-shadow: 2px -2px 1px 0px rgba(0, 0, 0, 0.5);
            transform: rotate(45deg);
            transition: margin-left .15s linear;
        }
        &.Next {
            &:hover:after {
                color: rgba(255, 255, 255, 0.9);
                box-shadow: 2px -2px 1px 0px rgba(0, 0, 0, 0.9);
            }
            &:after {
                right: 0.5rem;
                transform: rotate(45deg);
            }
        }
        &.Previous {
            &:hover:after {
                color: rgba(255, 255, 255, 0.9);
                box-shadow: 2px -2px 1px 0px rgba(0, 0, 0, 0.9);
            }
            &:after {
                left: 0.5rem;
                transform: rotate(-135deg);
            }
        }
    }
    .selector {
        -moz-user-select: none;
        user-select: none;
        cursor: pointer;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        max-height: 1.2rem;
        max-width: 1.2rem;
        height: 2vw;
        width: 2vw;
        border: 2px solid rgba(255, 255, 255, 0.4);
        background-color: rgba(0, 0, 0, 0.25);
        transition: background-color 0.5s ease-in-out;
        &.current {
            border: 2px solid rgba(0, 0, 0, 0.4);
            background-color: rgba(255, 255, 255, 0.85);
        }
        &:hover {
            border: 2px solid rgba(0, 0, 0, 0.4);
            background-color: rgba(255, 255, 255, 0.85);
        }
    }
}

.SlideShow {
    position: relative;
    z-index: 1;
    &.Home {
        margin-top: -4rem;
        margin-bottom: -6rem;
        background-color: rgba(0, 0, 0, 0.1);;
        .Slides {
            cursor: normal;
            .Slide {
                margin:auto;
                top: 0;
                width: 100%;
                img {
                    object-fit: cover;
                    background-size: cover;
                }
            }
        }
        .SlideSelect {
            position: absolute;
            bottom: 0.5rem;
        }
    }
    &.Fullscreen:not(.Home) {
        background-color: rgba(0, 0, 0, .8);
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 3000;
        padding: .5rem;
        .Slides {
            max-height: 100% !important;
            height: calc(100% - 3rem) !important;
            .Slide {
                width: 100%;
            }
        }
    }
}