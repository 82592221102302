$gretmar-blue: rgb(21, 34, 64);
$gretmar-blue-transparent: rgba(21, 34, 64, 0.8);
$gretmar-more-blue: #00579D;
$content-background: #f1f1f1;
$transparent-content-background: #f1f1f11c;
$footer-background: #212121;
$footer-text-color: #f1f1f1;
$margin-color: #d7d7e1;
//$banner-highlight: #4d566b;
$banner-highlight: #717377;
 
$banner-background: #e9eaec;
$banner-background-zebra: #dfdfdf;
$content-text-color: #595959;

$article-background: white;
$article-border: #d0cfcf;

$gretmar-wave-blue: #263771;
