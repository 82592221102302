@import "_constants.scss";
@import "_colors.scss";

.ContentMenu {
    color: $content-background;
    -moz-user-select: none;
    user-select: none;
    position: fixed;
    margin: 1rem;    
    margin-top: calc(4rem + 4px);
    padding-bottom: 1rem;
    background-color: lightgrey;
    border-bottom: 1px solid #e2e2e2;
    max-height: calc(100% - 14.5rem);
    overflow-y: auto;
    .Category {
        position: fixed;
        top: 8rem;
        width: 20rem;
        box-sizing: border-box;
        display: block;
        text-decoration: none;
        font-style: inherit;
        padding: 1.5em;
        font-size: 1em;
        font-weight: 700;
        text-shadow: 2px 2px $gretmar-blue;
        cursor: pointer;
        background-color: $gretmar-blue;
        color: $content-background;
        filter: grayscale(0%);
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border: 1px solid $gretmar-blue;
        // @include shadow;
    }
    .ItemList {
        display: block;
        .Item {
            color: $gretmar-blue;
            font-size: 1.0rem;
            padding: 0.5em;
            padding-left: 1.5rem;
            cursor: pointer;
            display: block;
            text-decoration: none;
            font-style: inherit;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            &.Divider {
                color: $gretmar-more-blue;
                font-weight: 900;
                text-indent: -.5rem;
            }
            &.NoMenu {
                display: none;
            }
            &:hover,
            &.active {
                background-color: $banner-highlight;
                color: $content-background;
            }
        }
    }
    .CategoryBorder {
        background-color: $gretmar-blue;
        height: 1px;
    }
}