@import "_constants.scss";
@import "_colors.scss";
@import "_global.scss";

.Actions {
    display: flex;
    flex-direction: row-reverse;
    margin: .5rem;
    position: relative;
    z-index: 1;
}

.Floating {
    &.Left {
        float: left;
        margin-right: 1rem;
        @media screen and (max-width: $small-screen-width) {
            float: none;
            margin: auto;
        }
    }
    &.Right {
        float: right;
        margin-left: 1rem;
        @media screen and (max-width: $small-screen-width) {
            float: none;
            margin: auto;
        }
    }
}

.Image {
    margin: 1rem;
    background-color: transparent;
    min-width: 16rem;
    max-height: 29rem;
    height: auto;
    h4 {
        text-align: center;
        margin: 0;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
        background-size: contain;
        background-position: center;
    }
    &.Floating {
        background-color: transparent;
        height: auto;
        width: 100%;
    }
}

.Images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: transparent;
    overflow: hidden;
    .Image {
        flex-basis: calc(50% - 2rem);
        flex-grow: 1;
    }
}

.Footer {
    height: 7rem;
    width: 100%;
    background-color: $footer-background;
    color: $footer-text-color;
    display: flex;
    flex-direction: column;
    z-index: 100;
    .FooterContent {
        height: 3rem;
        background-color: $gretmar-blue;
    }
    .CopyrightNotice {
        text-align: center;
        padding: 1rem;
        font-weight: bold;
        .GretmarCopyright {
            display: inline;
            padding-right: 15px;
        }
        a {
            color: lightblue;
            text-decoration: none;
            border-left: 2px solid white;
            padding-left: 15px;
            padding-right: 15px;
            &:visited {
                text-decoration: none;
            }
        }
    }
}

.Wrapper {
    background-color: $margin-color;
    height: 100%;
    width: 100%;
}

.WhatWeDo {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    .Item {
        width: 10rem;
        color: black;
        margin-bottom: 1rem;
        img {
            width: 10rem;
            height: 10rem;
        }
        .ItemTitle {
            font-weight: 500;
            text-align: center;
        }
        .ItemContent {
            text-align: center;
        }
    }
}

.Certifications {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    position:relative;
    .Image {
        min-width: 8rem;
        width: 8rem;
        color: black;
        padding: 1rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        padding-bottom: 0;
        img {
            height: 10rem;
            min-height: 10rem;
            object-position: center bottom;
            background-position: center bottom;
        }
        h3 {
            font-size: .8rem;
            text-align: center;
            margin-bottom: 0;
        }
    }
}

.Date {
    font-size: 0.9rem;
    text-align: right;
    margin: 0.2rem;
}

@import "_navigation.scss";
@import "_main_menu.scss";
@import "_hover_menu.scss";
@import "_search.scss";
@import "_banner.scss";
@import "_breadcrumbs.scss";
@import "_content_menu.scss";
@import "_contact_us.scss";
@import "_content.scss";
@import "_fancy_header.scss";
@import "_slideshow.scss";
@import "_box.scss";

// this should come last
@import "_warnings.scss";
  