@import "_constants.scss";
@import "_colors.scss";

html,
body {
    font: 100% $font-stack;
    color: $content-text-color;
    margin: 0;
    width: 100%;
    height: 100%;
}

html {
    overflow-y: scroll;
    scroll-behavior: smooth;
    &.FastScroll {
        scroll-behavior: auto;
    }
}

a {
    text-decoration: none;
    color: $gretmar-more-blue;
    display: inline-block;
    &:hover {
        text-decoration: underline;
    }
    &.LinkAfter {
        color: inherit;
        &:hover {
            text-decoration: none;
            &:after {
                margin-left: .5rem;
            }
        }
        &:after {
            content: '';
            display: inline-block;
            width: .5em;
            height: .5em;
            padding: 0;
            margin-left: .25rem;
            margin-bottom: 0.15rem;
            border-top: 2px solid;
            border-right: 2px solid;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            transition: margin-left .15s linear;
            position: absolute;
            bottom: 1.05em;
        }
    }
    &.ReadMore {
        border: 1px solid #7b848a;
        padding: 10px 20px;
        font-size: 1rem;
        text-decoration: none;
        color: $gretmar-blue;
        background-image: linear-gradient($banner-highlight, $banner-highlight);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 0% 100%;
        transition: background-size .2s, color .2s;
        &:hover {
            color: white;
            background-size: 100% 100%;
        }
    }
    &.ToTop {
        float: right;
        padding-top: 1rem;
        padding-right: 1rem;
    }
}

li {
    display: table;
    padding-bottom: .5rem;
    break-inside: avoid-column;
    &:before {
        display: table-cell;
        content: "\25CF";
        padding-right: 1rem;
    }
}

ul{
    columns: 2;
    list-style-position: inside;
    break-inside: avoid;
    margin: 0;
    @media screen and (max-width: $article-and-content-menu-max-width) {
        columns: 1;
    }
    @media screen and (max-width: $touch-style-max-width) {
        columns: 2;
    }
    @media screen and (max-width: $touch-style-two-column-max-width) {
        columns: 1;
    }
    li {
        ul {
            padding: 0;
            columns: 1;
            li {
                &:before {
                    content: "-";
                }
            }
        }
    }
}

dl {
    display: table;
    dt {
        margin-top: .5rem;
        font-weight: bold;
    }
    &.TwoColumn {
        display: block;
        columns: 2;
        @media screen and (max-width: $article-and-content-menu-max-width) {
            columns: 1;
        }
        @media screen and (max-width: $touch-style-max-width) {
            columns: 2;
        }
        @media screen and (max-width: $touch-style-two-column-max-width) {
            columns: 1;
        }
        dt {
            font-size: 0.9rem;
        }
    }
}

img{
    box-sizing: border-box;
    background-repeat: no-repeat;
}