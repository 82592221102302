@import "_constants.scss";
@import "_colors.scss";

.MainMenuWrapper {
    opacity: 0;
    z-index: 1000;
    position: fixed; // background-color: rgba(0, 0, 0, 0.4);
    color: black;
    left: 0;
    right: 0;
    max-width: 0;
    top: 4rem;
    bottom: 0;
    border-top: 2px solid $gretmar-blue;
    transition: max-width .5s ease, opacity 1s ease;
    width: 100%;
    cursor: default;
    overflow: hidden;
    &.active {
        opacity: 1;
        max-width: 100%;
        transition: max-width .5s ease;
        overflow-x: auto;
    }
    .MainMenu {
        display: table;
        margin: auto;
        width: 100%;
        height: 30rem;
        min-height: 30rem;
        max-height: 100%;
        position: absolute;
        overflow-y: hidden;
        min-width: 25rem;
        @include shadow;
        a {
            // transform: skew(30deg);
            margin: 0;
            color: inherit;
            font-size: inherit;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            text-indent: 0.5rem;
            cursor: pointer;
            text-decoration: none;
            position: relative;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            max-width: calc(100% - 1rem);
            text-overflow: ellipsis;
            &.Divider {
                color: $gretmar-more-blue;
                font-weight: bold;
                padding-left: .25rem;
            }
            &.NoMenu {
                display: none;
            }
            &:hover {
                background-color: $banner-highlight;
                color: white;
            }
        }
        @media screen and (max-width: $main-menu-min-width) {
            min-height: 100%;
        }
    }
}


.MainMenuContextMenu {
    display: table-cell;
    font-size: 1.2rem;
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
    background-color: $banner-highlight;
    color: $banner-background;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 1px 1px 0px black;
    border-right: 1px solid black;
    z-index: 1;
    vertical-align: top;
    .MainMenuContext {
        line-height: 1.2rem;
        text-align: left;
        padding: 1.0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;
        &:hover {
            color: $gretmar-blue;
            background-color: white;
        }
        &.active {
            color: $gretmar-blue;
            background-color: white;
        }
        &:after {
            content: '';
            display: inline-block;
            width: .5em;
            height: .5em;
            padding: 0;
            margin-left: .25rem;
            margin-bottom: 0.15rem;
            border-top: 2px solid;
            border-right: 2px solid;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            transition: margin-left .15s linear;
            position: absolute;
            top: 1.1em;
            right: 1rem;
        }
    }
}

.MainMenuCategories {
    display: none;
    font-size: 0.9rem;
    background-color: $content-background;
    color: $content-text-color;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: 100%;
    flex-wrap: wrap;
    right: 0;
    left: 10rem;

    &.active {
        display: flex;
    }
}

.MainMenuCategory {
    text-align: left;
    display: inline-block;
    min-width: 15rem;
    max-width: 15rem;
    width: 15rem;
    max-height: 30rem;
    font-weight: bolder;
    background-color: $content-background; // flex-grow: 1;
    vertical-align: top;
    .MainMenuCategoryHeader {
        color: $gretmar-more-blue;
        padding-top: 1.0rem;
        padding-left: 1.0rem;
        padding-right: 1.0rem;
        a {
            &:hover {
                background-color: transparent;
                color: inherit;
            }
        }
        &:after {
            content: "";
            display: block;
            margin-top: .5rem;
            margin-bottom: 1rem;
            margin-left: 1rem;
            width: 3.5rem;
            border-bottom: 3px solid;
        }
    }
    &:hover {
        background-color: #e6e6e6;
    }
}

.MainMenuArticle {
    font-size: 0.75rem;
    line-height: 1.5rem;
    text-align: left;
    color: $content-text-color;
    font-weight: normal;
    &:hover {
        background-color: $banner-highlight;
        color: white;
    }
}