
$font-stack: Lato, sans-serif;

$main-menu-min-width: 1640px;
$article-and-content-menu-and-contact-us-max-width: 1450px;
$article-and-content-menu-max-width: 1158px;
$touch-style-max-width: 1022px;
$touch-style-two-column-max-width: 823px;

$small-screen-width: 35rem;

$top-bar-fancy-dropdown-min-width: 1470px;
$top-bar-width-big: 1218px;
$top-bar-width-small: 732px;
$top-bar-width-tiny: 600px;
$top-bar-width-super-tiny: 500px;
$top-bar-width-absolute-tiny: 450px;

@mixin shadow() {
    box-shadow: 0px 1px 1px 0px $gretmar-blue-transparent;
}