@import "_constants.scss";
@import "_colors.scss";

.Navigation {
    position: fixed;
    top: 0;
    height: 4rem;
    width: 100vw; //need vw units when using position: fixed
    // overflow-y: scroll; //take into account the scrollbar in the body
    z-index: 100;
    @include shadow;
    -moz-user-select: none;
    user-select: none;
    background-color: $gretmar-blue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media screen and (max-width: $top-bar-width-big) {
        justify-content: flex-start;
    }
    a {
        font-style: inherit;
        text-decoration: none;
        color: inherit;
        &.Telephone {
            display: flex;
            align-items: center;
            position: absolute;
            right: 2rem;
            height: 100%;
            margin: auto;
            padding-left: 1.8rem;
            background-image: url(../images/tel.svg);
            background-size: 1.5rem 1.5rem;
            background-position: left center;
            background-repeat: no-repeat;
            cursor: pointer;
            color: white;
            overflow: hidden;
            white-space: nowrap;
            transition: all .15s ease;
            &:hover {
                background-size: 1.8rem 1.8rem;
            }
            @media screen and (max-width: $top-bar-width-tiny) {
                max-width: 0rem;
            }
            @media screen and (max-width: $top-bar-width-super-tiny) {
                display: none;
            }
        }
    }
    .Logo {
        cursor: pointer;
        background-image: url(../images/gretmar_logo_white.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        max-width: 20rem;
        min-width: 20rem;
        display: block;
        opacity: 1;
        @media screen and (max-width: $top-bar-width-big) {
            margin-left: 4rem;
        }
        @media screen and (max-width: $top-bar-width-super-tiny) {
            margin-left: 4rem;
            min-width: 0;
            max-width: 100%;
            width: calc(100% - 9rem);
        }
    }
    .Menu {
        text-align: center;
        list-style: none;
        padding: 0;
        padding-right: 1rem;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        &.DropDownMenuEnabled {
            display: none;
        }
        .Item {
            position: relative;
            color: $banner-background;
            line-height: 2rem;
            height: 100%;
            display: flex;
            justify-content: center;
            max-width: 8rem;
            &.Fancy {
                @media screen and (min-width: $top-bar-fancy-dropdown-min-width) {
                    &:hover {
                        >.HoverMenu {
                            display: none;
                            &.Fancy {
                                display: block;
                            }
                        }
                    }
                }
                @media screen and (max-width: $top-bar-fancy-dropdown-min-width) {
                    &:hover {
                        >.HoverMenu {
                            display: block;
                            &.Fancy {
                                display: none;
                            }
                        }
                    }
                }
            }
            &:hover {
                >.HoverMenu {
                    display: block;
                }
            }
            &:before {
                content: "";
                position: absolute;
                bottom: 1rem;
                left: 50%;
                height: 0;
                width: 0;
                border-bottom: 3px solid;
                visibility: hidden;
                background-color: $banner-background;
                transition: left .25s, width .25s;
            }
            &:hover,
            &.active {
                color: white;
                &:before {
                    visibility: visible;
                    width: 30px;
                    left: calc(50% - 15px);
                }
            }
            &.active {
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    height: 0;
                    width: 0;
                }
            }
        }
        a {
            // transform: skew(30deg);
            margin: 0;
            color: white;
            padding-left: 1.8rem;
            padding-right: 1.8rem;
            padding-bottom: 1.0rem;
            padding-top: 1.0rem;
            align-self: flex-end;
            display: flex;
            font-size: 1.0rem;
            color: inherit;
            cursor: pointer;
            text-decoration: none;
        }
    }
    @media screen and (max-width: $touch-style-max-width) {
        .Logo {
            margin-left: auto;
            margin-right: auto;
        }
        .Menu {
            display: none;
        }
    }
    @media screen and (max-width: $top-bar-width-small) {
        .Logo {
            margin-left: 4rem;
            margin-right: auto;
        }
    }
    @media screen and (max-width: $top-bar-width-tiny) {
        .Logo {
            transition: all 0.25s ease;
            &.Searching {
                opacity: 0;
            }
        }
    }
}

.NavigationMenuToggle {
    position: absolute;
    left: 1rem;
    top: 0rem;
    width: 30px;
    height: 100%;
    cursor: pointer;
    margin-right: 1rem;
    &.active {
        margin-left: auto;
    }
    &.active>.ThreeHorizontalBars {
        background-color: white;
        height: 0;
        &:after {
            background-color: white;
            top: 0px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            visibility: visible;
        }
        &:before {
            background-color: white;
            top: 0px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            visibility: visible;
        }
    }
    .ThreeHorizontalBars {
        height: 3px;
        width: 30px;
        top: calc(50% - 3px);
        background-color: $banner-background;
        display: block;
        position: relative;
        transition: background-color .15s linear;
        &:before {
            content: "";
            top: -7px;
            height: 3px;
            width: 30px;
            display: block;
            position: absolute;
            background-color: $banner-background;
            transition: background-color .15s linear;
            transition: top .15s linear;
            transition: transform .15s linear;
        }
        &:after {
            content: "";
            top: 7px;
            height: 3px;
            width: 30px;
            display: block;
            position: absolute;
            background-color: $banner-background;
            transition: background-color .15s linear;
            transition: top .15s linear;
            transition: transform .15s linear;
        }
    }
}